import Cookies from "js-cookie";
import dynamic from "next/dynamic";
import { page_information } from "@/lib/gtm";
import { useRouter } from "next-nprogress-bar";
import { constants } from "@/lib/utils/constants";
import { getVehicleInfo } from "@/lib/services/api";
import useAxiosInterceptor from "./useAxiosInterceptor";
import { updateBranch } from "@/lib/store/reducers/misc";
import { useAppDispatch, useAppSelector } from "@/lib/store";
import { updateVehicle } from "@/lib/store/reducers/vehicle";
import { usePathname, useSearchParams } from "next/navigation";
import { useRedirectToHome } from "@/lib/hooks/useRedirectToHome";
import { useEffect, useMemo, useState, useCallback } from "react";
import { removeCartFromStorage } from "@/lib/utils/browserMethods";
import { fetchGlobalAssets,updateCustomerGroup } from "@/lib/store/reducers/globalAssets";
import { getSelectedBranchFromStorage } from "@/lib/utils/authMethods";
import { fetchCustomer, setCustomerToken } from "@/lib/store/reducers/customer";
import {
  applyCouponToCart,
  getDefaultAutoApplyCouponCode,
} from "@/lib/services/cart";
import {
  isVRM,
  getPageType,
  handleToastError,
  handleThunkResponse,
  MVLKeyToQueryString,
  handleGraphqlResponse,
} from "@/lib/utils/functions";
import {
  setCartId,
  fetchCartItems,
  getCartProducts,
  fetchClickAndCollectStock,
  updateInitialAlerts,
} from "@/lib/store/reducers/cart";
import {
  updateAppInitailized,
  updateIsBasketLoader,
} from "@/lib/store/reducers/uiState";
import { firePageViewEvent, setContext } from "@/lib/utils/livesearch";
import { getAdobeStoreConfig } from "@/lib/services/general";

const ProgressBar = dynamic(
  () => import("next-nprogress-bar").then((mod) => mod.AppProgressBar),
  {
    ssr: false,
  },
);

const useAppInitialization = () => {
  const router = useRouter();
  const pathname = usePathname();
  const dispatch = useAppDispatch();
  const searchParams = useSearchParams();

  useRedirectToHome({ router, pathname, cookieKeys: constants.cookies });
  useAxiosInterceptor({ router, dispatch });

  const merchantReferenceToken = searchParams.get("merchantReference");
  const cartProducts = useAppSelector(getCartProducts);
  const { vehicle } = useAppSelector((state) => state.vehicle);
  const { loading, appInitailized } = useAppSelector((state) => state.uiState);
  const { storeWindowSet } = useAppSelector((state) => state.misc);
  const [productsState, setProductsState] = useState({});
  const [routeLoading, setRouteLoading] = useState(false);
  
  useEffect(() => {
    if (appInitailized) {
      const getDepartment = () => {
        const match = location?.pathname.match(/^\/([^\/]+)/);
        if (match) {
          const department = match[1];
          return department.charAt(0).toUpperCase() + department.slice(1);
        }
        return "";
      };

      const hasVehicle = Cookies.get(constants.cookies.selectedVehicle);
      page_information({
        page_location: location?.href,
        page_title: document.title,
        page_type: getPageType(),
        ...(getPageType() === "Sub Category" && {
          department: getDepartment(),
        }),
        signed_in_status: Cookies.get(constants.cookies.customerToken)
          ? "logged in"
          : "logged out",
        registration_status: hasVehicle ? "True" : "False",
        ...(hasVehicle && {
          manufacturer: vehicle?.vehicle?.make,
          model: vehicle?.vehicle?.model,
          ...(vehicle?.vehicle?.engineSize && {
            engine: vehicle?.vehicle?.engineSize,
          }),
          years:
            vehicle?.vehicle?.modelVersion?.match(/\d{4}/)[0] ||
            vehicle?.vehicle?.dateFirstRegistered?.match(/^(\d{4})/)[0],
        }),
      });
    }
  }, [appInitailized, pathname]);

  useEffect(() => {
    if (
      appInitailized &&
      storeWindowSet &&
      !constants.excludedPaths.includes(pathname) &&
      !pathname.includes("/products/")
    ) {
      firePageViewEvent();
    }
  }, [pathname, appInitailized, storeWindowSet]);

  const hydrateData = useCallback(async () => {
    try {
      const query = Cookies.get(constants.cookies.selectedVehicle);
      if (query) {
        let vRes = null;
        if (isVRM(query)) {
          vRes = await getVehicleInfo(`vrm=${query}`);
        } else {
          vRes = await getVehicleInfo(MVLKeyToQueryString(query));
        }

        if (vRes?.data) {
          dispatch(updateVehicle(vRes?.data));
        }
      }
    } catch (err) {
      handleToastError(`Error fetching vehicle details: ${err.message}`);
    }

    // Get query params and set them to cookies
    const autoApplyCoupon = searchParams.get("auto_apply_coupon");
    if (autoApplyCoupon) {
      // Set the specific coupon to the cookie if auto_apply_coupon is available
      Cookies.set(constants.cookies.specialCoupon, autoApplyCoupon, {
        secure: true,
        sameSite: "strict",
      });
    }

    const [customerTokenCipher, specialCoupon, specialCouponInitalLoadCheck] = [
      Cookies.get(constants.cookies.customerToken),
      Cookies.get(constants.cookies.specialCoupon),
      Cookies.get(constants.cookies.specialCouponInitalLoadCheck),
    ];

    const fetchDefaultCoupon = async () => {
      if (!specialCoupon && !specialCouponInitalLoadCheck) {
        try {
          const response = await getDefaultAutoApplyCouponCode();
          const defaultCoupon = response.data?.getDefaultAutoApplyCouponCode;
          if (defaultCoupon) {
            Cookies.set(constants.cookies.specialCoupon, defaultCoupon, {
              secure: true,
              sameSite: "strict",
            });
            Cookies.set(constants.cookies.specialCouponInitalLoadCheck, "1", {
              secure: true,
              sameSite: "strict",
            });
          }
        } catch (error) {
          handleToastError(
            `Error fetching default auto-apply coupon code: ${error.message}`,
          );
        }
      }
    };

    const fetchGlobal = async () => {
      try {
        const globalAssetsRes = await dispatch(fetchGlobalAssets());
        handleThunkResponse(globalAssetsRes);
      } catch (error) {
        handleToastError(`Error fetching global assets: ${error.message}`);
      }
    };

    const fetchConfig = async () => {
      try {
        const res = await getAdobeStoreConfig();
        const data = handleGraphqlResponse(res);
        dispatch(updateCustomerGroup(data?.dataServicesStorefrontInstanceContext?.customer_group));
        setContext(data,dispatch);
      } catch (error) {
        handleToastError(
          `Error fetching Adobe Commerce Store Config: ${error.message}`,
        );
      }
    };

    const fetchCart = async () => {
      try {
        let cartId = localStorage.getItem(constants.localStorage.cartId);

        if (customerTokenCipher) {
          dispatch(setCustomerToken(customerTokenCipher));
          const custmerDetails = await dispatch(fetchCustomer());

          if (custmerDetails?.payload?.cart_id) {
            localStorage.setItem(
              constants.localStorage.cartId,
              custmerDetails?.payload?.cart_id,
            );
            cartId = custmerDetails?.payload?.cart_id;
          } else {
            cartId = null;
            removeCartFromStorage(constants.localStorage.cartId);
          }
        }

        if (cartId) {
          dispatch(setCartId(cartId));
        }

        const selectedBranch = getSelectedBranchFromStorage();
        dispatch(updateBranch(selectedBranch));

        if (selectedBranch) {
          const storedAlerts = localStorage.getItem(
            constants.localStorage.cncAlerts,
          );

          const parsedAlerts = JSON.parse(storedAlerts);
          dispatch(updateInitialAlerts(parsedAlerts));
        }

        if (cartId && !merchantReferenceToken) {
          localStorage.removeItem(
            constants.localStorage.shouldFetchCartDetails,
          );

          if (autoApplyCoupon) {
            await applyCouponToCart({ couponCode: autoApplyCoupon });
          }

          const cartDetailsRes = await dispatch(
            fetchCartItems({ getBranchInfo: !selectedBranch }),
          );
          const cartDetails = handleThunkResponse(cartDetailsRes);

          if (
            cartDetails?.branchId &&
            window?.location?.pathname !== "/checkout/cart"
          ) {
            await dispatch(
              fetchClickAndCollectStock({ branch_id: cartDetails.branchId }),
            );
          }
        }
      } catch (error) {
        handleToastError(`Error fetching cart items: ${error.message}`);
      }
      dispatch(updateIsBasketLoader(true));
    };

    await Promise.allSettled([
      fetchDefaultCoupon(),
      fetchGlobal(),
      fetchCart(),
      fetchConfig(),
    ]);
    dispatch(updateAppInitailized(true));
  }, [dispatch]);

  useEffect(() => {
    hydrateData();
  }, [hydrateData]);

  useEffect(() => {
    // This will run when user click on add to basket
    const cartTotal = Object.keys(cartProducts).length;
    const productTotal = Object.keys(productsState).length;
    const hasCartItem = cartTotal > 0;
    const hasProducts = productTotal > 0;

    if (!hasCartItem && hasProducts) {
      setProductsState(cartProducts);
    } else if (hasCartItem && !hasProducts) {
      setProductsState(cartProducts);
    } else if (cartTotal !== productTotal) {
      setProductsState(cartProducts);
    } else if (hasCartItem && hasProducts) {
      const compareItems = (arr1, arr2) => {
        for (const sku in arr1) {
          // Check if the SKU exists in both objects
          if (!arr2.hasOwnProperty(sku)) return false;
          // Compare quantities
          if (arr1[sku].quantity !== arr2[sku].quantity) return false;
        }
        return true;
      };

      const isEqual = compareItems(productsState, cartProducts);
      if (!isEqual) {
        setProductsState(cartProducts);
      }
    }
  }, [cartProducts]);

  const appContextData = useMemo(
    () => ({
      productsState,
      setProductsState,
    }),
    [productsState, setProductsState],
  );

  useEffect(() => {
    const htmlEl = document.querySelector("html");
    const config = { attributes: true };
    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "class"
        ) {
          setRouteLoading(mutation.target.className.includes("nprogress-busy"));
          setTimeout(() => {
            setRouteLoading(false);
          }, 5000);
        }
      }
    });
    observer.observe(htmlEl, config);
    return () => observer.disconnect();
  }, [dispatch]);

  const AppProgressBar = () => {
    return (
      <ProgressBar
        options={{
          showSpinner: false,
        }}
        style={`#nprogress { display: none; }`}
      />
    );
  };

  return {
    data: {
      loading,
      routeLoading,
      appContextData,
    },
    components: {
      AppProgressBar,
    },
    methods: {
      dispatch,
    },
  };
};

export default useAppInitialization;
